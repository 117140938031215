import { IpService } from './../shared/services/ip.service';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { IconSetService } from '@coreui/icons-angular';
import { iconSubset } from './icons/icon-subset';
import { Title } from '@angular/platform-browser';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  title = 'HTQL MÃ HOÁ LÂM SÀNG KHÁM CHỮA BỆNH';
  constructor(
    private router: Router,
    private titleService: Title,
    private iconSetService: IconSetService,
    private ipService:IpService
  ) {
    titleService.setTitle(this.title);
    // iconSet singleton
    iconSetService.icons = { ...iconSubset };
  }

  ngOnInit(): void {
    
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

    });
    this.ipService.getClientIp().subscribe(
      (data: any) => {
        const clientIp = data.ip;
        // Lưu địa chỉ IP vào localStorage
        localStorage.setItem('client_ip', clientIp);
      },
      (error) => {
        console.log('Error while fetching client IP:', error);
      }
    );
  }
}
