<!--sidebar-->
<c-sidebar #sidebar="cSidebar" class="d-print-none sidebar sidebar-fixed" id="sidebar" visible>
    <c-sidebar-brand routerLink="./" class="w-fit h-fit bg-white">
        <div>
            <img
                ngSrc="assets/img/brand/logo.jpg"
                alt="CoreUI Logo"
                class="object-cover w-full h-full"
                height="392" width="1792" />
        </div>
    </c-sidebar-brand>

    <perfect-scrollbar [config]="perfectScrollbarConfig">
        <c-sidebar-nav [navItems]="navItems" dropdownMode="close" > </c-sidebar-nav>
    </perfect-scrollbar>
    <div class="flex items-center justify-center text-xs text-gray-500 gap-1 p-2 bg-gray-100 rounded-md shadow-sm ml-2 mr-2 mb-2">
        <a href="https://whiteneurons.com/" class="text-gray-500 hover:text-gray-700 capitalize font-medium transition duration-300">
            white Neuron Co. Ltd.
        </a>
        <span class="mx-1">|</span>
        <span class="text-gray-500 font-medium">@2024</span>
    </div>
</c-sidebar>

<!--main-->
<div class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
    <!--app-header-->
    <app-default-header
        class="mb-4 d-print-none header header-sticky"
        position="sticky"
        sidebarId="sidebar"
    ></app-default-header>
    <!--app-body-->
    <div class="body flex-grow-1 px-3">
        <router-outlet></router-outlet>
    </div>
    <!--app footer-->
    <app-default-footer></app-default-footer>
</div>

<!-- Scroll to top and bottom, nằm bên phải màn hình, và ở giữa chiều -->
<div class="scroll-container">
    <button class="scroll-top" [ngClass]="{'open': isShow}" (click)="scrollToTop()">
        <i class="fas fa-angle-double-up"></i>
    </button>
    <button class="scroll-down scroll-to-target open" [ngClass]="{'open': !isBottom}" (click)="scrollToBottom()">
        <i class="fas fa-angle-double-down"></i>
    </button>
</div>
