import { AppService } from './../../../../shared/services/app.service';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FooterComponent } from '@coreui/angular';
// Import your API service

@Component({
    selector: 'app-default-footer',
    templateUrl: './default-footer.component.html',
    styleUrls: ['./default-footer.component.scss'],
})
export class DefaultFooterComponent extends FooterComponent implements OnInit {
    message:string="";
    constructor(private apiService: AppService,  private sanitizer: DomSanitizer) {
        super();
    }
    sanitizeHTML(html: string): SafeHtml {
      return this.sanitizer.bypassSecurityTrustHtml(html);
    }
    ngOnInit(): void {
        // Gọi API ở đây khi component được render lần đầu tiên
        this.apiService.getTb().subscribe(
            (data) => {
                // Xử lý dữ liệu trả về từ API
                this.message=data.content
            },
            (error) => {
                // Xử lý lỗi nếu có
                this.message=""
            }
        );
    }
}
