// export const API_BASE= "https://icd.whiteneuron.com";
// export const API_BASE = "http://192.168.50.86:8000";
// export const API_BASE= ""; // for deploy
// export const API_BASE= "http://localhost:8000"; // for run local with backend
export const API_BASE= "https://ccms.whiteneuron.com";
// export const API_DASHBOARD="http://192.168.50.95:8000"
// export const API_DASHBOARD="https://api-vs-dashboard.whiteneurons.com"
export const API_DASHBOARD= `${API_BASE}/api/MCCD`;
// export const API_VUNGHI="http://192.168.50.198:8000"
// export const API_VUNGHI = 'https://icd.kcb.vn';
export const API_VUNGHI = API_BASE;
export const API_URL = API_BASE;
export const API_ICF = `${API_BASE}/api/ICF`;
// export const API_ICD = "https://icd.whiteneurons.com/api/ICF";
// export const API_ICF = 'http://127.0.0.1:8000/api';
export const API_TOOL = `${API_BASE}/api/ucod/diagnose`;