<a class="nav-link" href="javascript:void(0);" (click)="display = !display">
  <span *ngIf="langKey === 'vi'">
    <img ngSrc="../../../../assets/images/vietnamese.svg" alt="vietnamese" height="20" width="30" />
  </span>
  <span *ngIf="langKey === 'en'">
    <img ngSrc="../../../../assets/images/english.svg" alt="english" height="21" width="30" />
  </span>
</a>

<div class="language cursor-pointer" *ngIf="display">
  <div [class.active]="langKey === 'vi'" class="p-3 list-lang" (click)="changeLanguage('vi')">
    <img ngSrc="../../../../assets/images/vietnamese.svg" alt="" height="20" width="30" />&nbsp;
    <span [class.active]="langKey === 'vi'">Việt Nam</span>
  </div>
  <div [class.active]="langKey === 'en'" class="p-3 w-full hover-flag list-lang " (click)="changeLanguage('en')">
    <img ngSrc="../../../../assets/images/english.svg" alt="" height="21" width="30" />&nbsp;
    <span [class.active]="langKey === 'en'" >English</span>
  </div>
</div>
