import { Component, Input } from '@angular/core';
import { ClassToggleService, HeaderComponent, SidebarModule } from '@coreui/angular';
import {EventManager} from "../../../../shared/services/broadcast.service";
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
})
export class DefaultHeaderComponent extends HeaderComponent {

  @Input() sidebarId: string = "sidebar";
  langKey: string | null = 'vi';

  public newMessages = new Array(4)
  public newTasks = new Array(5)
  public newNotifications = new Array(5)
  isShowLang: boolean = true;
  constructor(private classToggler: ClassToggleService, private eventManager: EventManager,private router: Router) {
    super();
    this.eventManager.subscribe('changeLanguage', (lang: any) => {
      this.langKey = lang.content;
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkURL();
      }
    });
  }
  
  // Mỗi lần url thay đổi thì sẽ so sánh lại với url của global
  ngOnInit(): void {
    this.langKey = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'vi';
   
  }
  checkURL(): void {
    if (window.location.href.split('/')[4].includes('global')) {
      this.isShowLang = false;
    }
    else{
      this.isShowLang = true;
    }
  }
}
