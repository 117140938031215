import { Component , AfterViewInit, OnDestroy, HostListener } from '@angular/core';

import { navItems,navItems2 } from './_nav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
})
export class DefaultLayoutComponent  implements AfterViewInit, OnDestroy{

  public navItems = navItems;

  public perfectScrollbarConfig = {
    suppressScrollX: true,
  };

  constructor() {}
  ngAfterViewInit() {
    window.addEventListener('resize', this.updateNavItems.bind(this));
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.updateNavItems.bind(this));
  }

  updateNavItems() {
    if (window.innerWidth < 900) {
      this.navItems = navItems2; // Change to navItems2 if window width is less than 900
    } else {
      this.navItems = navItems; // Otherwise, use default navItems1
    }
  }

  // Hàm để cuộn về đầu trang
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  scrollToBottom() {
      const documentHeight = document.documentElement.scrollHeight;
      window.scrollTo({ top: documentHeight, behavior: 'smooth' });
  }

  // Logic ẩn/hiện nút khi cuộn trang
  isBottom: boolean = false;
  isShow: boolean = false;
  topPosToStartShowing = 100;


  @HostListener('window:scroll')
  checkScroll() {
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      if (scrollPosition >= this.topPosToStartShowing) {
          this.isShow = true;
      } else {
          this.isShow = false;
      }
      // Kiểm tra vị trí để ẩn nút cuộn xuống khi đã tới cuối trang
      if ((windowHeight + scrollPosition) >= documentHeight) {
          this.isBottom = true;
      } else {
          this.isBottom = false;
      }
  }

}
