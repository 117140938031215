import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {EventManager, EventWithContent} from "../../../../shared/services/broadcast.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {
  @Input() langKey: any;
  @Input() display = false;
  @Output() dismissEmitter = new EventEmitter();

  constructor( private eventManager: EventManager,public translate: TranslateService) { }

  ngOnInit(): void {
    let lang = localStorage.getItem('lang')
    if (typeof lang === "string") {
      this.translate.use(lang)
    }
  }
  changeLanguage(languageKey: string): void {
    localStorage.setItem('lang', languageKey);
    this.eventManager.broadcast(new EventWithContent('changeLanguage', languageKey));
    this.display = false;
    window.location.reload();
    this.translate.use(languageKey)
  }

}
