import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((ref: any) => {
        if ((window as any)['ngRef']) {
            (window as any)['ngRef'].destroy(); // Type assertion to 'any'
        }
        (window as any)['ngRef'] = ref; // Type assertion to 'any'
    })
    .catch((err) => console.error(err));
