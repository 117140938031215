import { INavData } from '@coreui/angular';
import { API_BASE } from 'src/shared/common';

export const navItems: INavData[] = [
    {
        name: 'ICD Smart Search',
        url: 'search/search-global',
        iconComponent: { name: 'cil-search' },
    },
    {
        name: 'Bảng thống kê',
        url: '',
        iconComponent: { name: 'cil-chart-pie' },
        children: [
            // {
            //     name: 'Hệ thống',
            //     url: '/dashboard/system',
            // },
            {
                name: 'UCOD',
                url: '/dashboard/ucod',
            },
            {
                name: 'MCCD',
                url: '/dashboard/mccd',
            },
        ],
    },
    {
        name: 'Công cụ',
        url: '/tools',
        iconComponent: { name: 'cilCalculator' },
        children: [
            //  {
            //      name: 'UCOD Việt Nam',
            //      url: `${API_BASE}/ucod`,
            //  },
             {
                 name:'UCOD Việt Nam',
                 url: 'tools/ucod',

             },
            {
                name: 'DRG Việt Nam',
                url: `${API_BASE}/VNDRG`,
            },
        ],
    },
    {
        name: 'ICD-10',
        url: '/icd-10',
        iconComponent: { name: 'cilSun' },
        children: [
            {
                name: 'ICD-10',
                url: '/icd-10/icd10',
            },
            {
                name: 'ICD-10 (Song ngữ)',
                url: '/icd-10/icd10-dual',
            },
            {
                name: 'Tài liệu ICD-10',
                url: '/icd-10/instruction-icd10',
            },
        ],
    },
    // {
    //     name: 'ICD-11',
    //     url: '/icd-11',
    //     iconComponent: { name: 'cilSun' },
    //     children: [
    //         {
    //             name: 'ICD-11',
    //             url: '/icd-11/icd11',
    //         },
    //         {
    //             name: 'ICD-10 (Song ngữ)',
    //             url: '/icd-10/icd10-dual',
    //         },
    //         {
    //             name: 'Tài liệu ICD-10',
    //             url: '/icd-10/instruction-icd10',
    //         },
    //     ],
    // },
    {
        name: 'ICD-9 CM',
        url: '/icd-9',
        iconComponent: { name: 'cilMoon' },
        children: [
            {
                name: 'ICD-9 CM',
                url: '/icd-9/icd9',
            },
            {
                name: 'ICD-9 CM (Song ngữ)',
                url: '/icd-9/icd9-dual',
            },
            {
                name: 'Tài liệu ICD-9 CM',
                url: '/icd-9/instruction-icd9',
            },
        ],
    },
    {
        name: 'ICF',
        url: '/icf',
        iconComponent: { name: 'cilStar' },
        children: [
            {
                name: 'ICF',
                url: '/icf/icf',
            },
            {
                name: 'ICF (Song ngữ)',
                url: '/icf/icf-dual',
            },
            {
                name: 'ICF Core Sets (Thử nghiệm)',
                url: `${API_BASE}/ICF/home`,
            },
            {
                name: 'Tài liệu ICF',
                url: '/icf/instruction-icf',
            },
        ],
    },
    {
        name: 'Có gì mới ?',
        url: 'notification/notification-list',
        iconComponent: { name: 'cilList' },
    },
];

export const navItems2: INavData[] = [
    // {
    //     title: true,
    //     name: 'apps',
    // },
    {
        name: 'ICD Smart Search',
        url: 'search/search-global',
        iconComponent: { name: 'cil-search' },
    },
    {
        name: 'Bảng thống kê',
        url: '/apps',
        iconComponent: { name: 'cil-chart-pie' },
        children: [
            // {
            //     name: 'Hệ thống',
            //     url: '/apps/dashboard/system',
            // },
            {
                name: 'UCOD',
                url: '/apps/dashboard/ucod',
            },
            {
                name: 'MCCD',
                url: '/apps/dashboard/mccd',
            },
        ],
    },
   
    {
        name: 'Công Cụ',
        url: '/tools',
        iconComponent: { name: 'cilCalculator' },
        children: [
            // {
            //     name: 'UCOD Việt Nam',
            //     url: `${API_BASE}/ucod`,
            // },
            // {
            //     name:'UCOD Việt Nam',
            //     url: 'tools/ucod',

            // },
            {
                name: 'DRG Việt Nam',
                url: `${API_BASE}/VNDRG`,
            },
        ],
    },
    {
        name: 'ICD-10',
        url: '/icd-10',
        iconComponent: { name: 'cilSun' },
        children: [
            {
                name: 'ICD-10',
                url: '/icd-10/icd10',
            },
            {
                name: 'ICD-10 (Song ngữ)',
                url: '/icd-10/icd10-dual',
            },
            {
                name: 'Tài liệu ICD-10',
                url: '/icd-10/instruction-icd10',
            },
        ],
    },
    {
        name: 'ICD-9 CM',
        url: '/icd-9',
        iconComponent: { name: 'cilMoon' },
        children: [
            {
                name: 'ICD-9 CM',
                url: '/icd-9/icd9',
            },
            {
                name: 'ICD-9 CM (Song ngữ)',
                url: '/icd-9/icd9-dual',
            },
            {
                name: 'Tài liệu ICD-9 CM',
                url: '/icd-9/instruction-icd9',
            },
        ],
    },
    {
        name: 'ICF',
        url: '/icf',
        iconComponent: { name: 'cilStar' },
        children: [
            {
                name: 'ICF',
                url: '/icf/icf',
            },
            {
                name: 'ICF (Song ngữ)',
                url: '/icf/icf-dual',
            },
            {
                name: 'ICF Core Sets (Thử nghiệm)',
                url: `${API_BASE}/ICF/home`,
            },
            {
                name: 'Tài liệu ICF',
                url: '/icf/instruction-icf',
            },
        ],
    },
    {
        name: 'Có gì mới ?',
        url: 'notification/notification-list',
        iconComponent: { name: 'cilList' },
    },
];
