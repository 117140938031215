<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container>
  <c-container [fluid]="true">
    <button
      toggle="visible"
      cHeaderToggler
      [cSidebarToggle]="sidebarId"
      class="ps-1"
    >
      <svg cIcon
           name="cilMenu"
           size="lg"
      ></svg>
    </button>
    <c-header-nav class="d-none d-lg-flex flex-1 me-auto">

    <p class="highlight-xanh-y-te w-full h-full flex flex-col uppercase font-medium text-[20px] mb-0">
      HỆ THỐNG QUẢN LÝ MÃ HOÁ LÂM SÀNG KHÁM CHỮA BỆNH
      <span class="english-subtitle font-normal text-[12px] mt-1 uppercase">clinical coding management system</span>
    </p>
    </c-header-nav>
    <c-header-nav class="d-none d-lg-flex" *ngIf="isShowLang">
            <app-language [langKey]="langKey"></app-language>
    </c-header-nav>

  </c-container>
  <c-header-divider></c-header-divider>
  <c-container [fluid]="true">
    <c-breadcrumb-router class="ms-2"></c-breadcrumb-router>
  </c-container>
</ng-container>