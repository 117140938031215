import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DefaultLayoutComponent } from './containers';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'search/search-global',
        pathMatch: 'full'
    },
    {
        path: '',
        component: DefaultLayoutComponent,
        data: {
            title: 'Trang chủ'
        },
        children: [
            {
                path: 'search',
                loadChildren: () => import('./views/who/who.module').then((m) => m.SearchGlobalModule),
            },
            {
                path: 'notification',
                loadChildren: () => import('./views/who/who.module').then((m) => m.NotificationModule),
            },
            {
                path: 'dashboard',
                loadChildren: () => import('./views/who/who.module').then((m) => m.DashboardModule)
            },
            {
                path: 'tools',
                loadChildren: () => import('./views/who/who.module').then(m => m.ToolsModule),
            },
            {
                path: 'icd-10',
                loadChildren: () => import('./views/who/who.module').then((m) => m.Icd10Module),
            },
            {
                path: 'icd-9',
                loadChildren: () => import('./views/who/who.module').then((m) => m.Icd9Module),
            },
            {
                path: 'icf',
                loadChildren: () => import('./views/who/who.module').then((m) => m.IcfModule),
            },
        ]
    }
]

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'top',
            anchorScrolling: 'enabled',
            initialNavigation: 'enabledBlocking',
            useHash:false
        }),
    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy,
        },
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}


