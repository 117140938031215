import { API_BASE } from './../common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  tb_api=`${API_BASE}/api/notification/`
  
  constructor(private http: HttpClient) { }
  getTb() {
        
    return this.http.get<any>(this.tb_api);
}
}
